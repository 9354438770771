import {
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild,
  OnInit,
  OnDestroy,
  AfterViewInit,
  forwardRef,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { REGEXP_EMAIL } from '../../data/regexp';
import { ConfigService } from '../../services/config.service';
import { RolesGet } from '../../store/actions/roles.action';
import {
  GetUsersListByTenant,
  GetUsersListFromAllUserTenants,
} from '../../store/actions/users.action';
import { AuthState } from '../../store/states/auth.state';
import { UsersState } from '../../store/states/users.state';
import { RolesState } from '../../store/states/roles.state';
import { SpacesState } from '../../store/states/spaces.state';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Capacitor } from '@capacitor/core';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';
import { TruncatePipe } from '../../pipes/truncate.pipe';
import { SvgComponent } from '../../svgs/svg/svg.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';

import {
  FormArray,
  FormControl,
  FormGroup,
  FormRecord,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgScrollbar } from 'ngx-scrollbar';
import { AvatarComponent } from '../../../standalone/components/avatar/avatar.component';
import { AutocompleteComponent, AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgIf, NgFor, AsyncPipe, CommonModule } from '@angular/common';
import { MixpanelService } from '../../../plugins/mixpanel/mixpanel.service';
import { UsersDbDto, UsersPublicFieldsResDto } from '../../../api/models';
import { InviteUsersToTenantAndSpaces, TenantInvite } from '../../store/actions/auth.action';
import { v4 } from 'uuid';

@Component({
  selector: 'app-tenant-invite',
  templateUrl: './tenant-invite.component.html',
  styleUrls: ['./tenant-invite.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    NgIf,
    AutocompleteLibModule,
    forwardRef(() => AvatarComponent),
    NgScrollbar,
    NgFor,
    NgSelectModule,
    FormsModule,
    SvgComponent,
    AsyncPipe,
    TruncatePipe,
    ReactiveFormsModule,
    CdkAccordionModule,
    CommonModule,
  ],
})
export class TenantInviteComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() platform: string;

  @ViewChild('autocomplete') autocomplete: AutocompleteComponent;

  destroy$: Subject<void> = new Subject<void>();
  config: any = {};
  usersFromAllTenants: UsersDbDto[];
  candidateEntityMembers: UsersDbDto[];
  currentUser: UsersPublicFieldsResDto;
  searchedInput: string;
  keyword = 'userName_email';
  selectedMember: any;
  isSelectedExist = false;
  roles: Observable<any[]>;
  inviteCompleted = false;
  mobileOs: string;
  enteredEmailListLengthLimit = 50;
  tenantName: string;

  spaces: {
    id: string;
    name: string;
  }[] = [];

  availableSpacesOfMembers: Record<
    string,
    {
      spaces: {
        id: string;
        name: string;
      }[];
      count: number;
    }
  > = {};

  form: FormRecord<
    FormGroup<{
      id: FormControl<string | undefined>;
      email: FormControl<string>;
      userName: FormControl<string | undefined>;
      isExternal: FormControl<boolean>;
      spaces: FormArray<
        FormGroup<{
          trackId: FormControl<string>;
          spaceId: FormControl<string | undefined>;
          roleName: FormControl<string | undefined>;
        }>
      >;
    }>
  > = new FormRecord({});

  constructor(
    private cdr: ChangeDetectorRef,
    private store: Store,
    private toastr: ToastrService,
    private configService: ConfigService,
    private activeModal: NgbActiveModal,
    private translocoService: TranslocoService,
  ) {
    this.config = this.configService.templateConf;
    this.currentUser = this.store.selectSnapshot(AuthState.getUser);
  }

  get invitedMembers() {
    return Object.values(this.form.controls).reverse();
  }

  /**
   * Called on invite return
   */
  private onInviteCompleted() {
    this.inviteCompleted = true;

    this.store.dispatch(new GetUsersListByTenant());
    this.cdr.detectChanges();
  }

  private prepareTenantMembers = (usersArr: UsersDbDto[]) => {
    const uniqueUsers: (UsersDbDto & {
      aka: Set<string>;
      userName_email: string;
    })[] = [];

    for (const userItem of usersArr) {
      const uniqueUser = uniqueUsers.find((user) => user.email === userItem.email);

      if (uniqueUser) {
        if (uniqueUser.userName !== userItem.userName && !uniqueUser.aka.has(userItem.userName)) {
          uniqueUser.aka.add(userItem.userName);
        }
        continue;
      }

      uniqueUsers.push({
        ...userItem,
        aka: new Set<string>(),
        userName_email: `${userItem.userName}  (${userItem.email})`,
      });
    }

    return uniqueUsers.map((uniqueUser) => ({
      ...uniqueUser,
      aka: [...uniqueUser.aka],
    }));
  };

  ngOnInit() {
    this.mobileOs = Capacitor.getPlatform();

    const userListOfAllUserTenants$ = this.store.dispatch(new GetUsersListFromAllUserTenants());

    const usersOfAllTenantsOfCurrentUser$ = this.store
      .select(UsersState.usersofAllTenantsOfCurrentUser)
      .pipe(
        map((users) => users.filter((user) => user.tenantName !== this.currentUser.tenantName)),
      );

    combineLatest([userListOfAllUserTenants$, usersOfAllTenantsOfCurrentUser$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([_, usersFromAllTenants]) => {
        this.usersFromAllTenants = this.prepareTenantMembers(usersFromAllTenants);
        this.initMainLogic();
      });
    MixpanelService.trackEvent('Tenant Invite: Open');
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public ngAfterViewInit() {
    this.configService.templateConf$.pipe(takeUntil(this.destroy$)).subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
        this.cdr.detectChanges();
      }
    });
  }

  /**
   * Invite users button click handler
   */
  usersInviteClick() {
    MixpanelService.trackEvent('Tenant Invite: Invite');
    if (this.doesMemberContainEmptyRole()) {
      this.toastr.error(
        this.translocoService.translate('toastr.all-selected-invitees-must-have-a-role'),
      );
      return;
    }

    const users = this.form.getRawValue();

    if (!Object.keys(users).length) {
      // TODO(David): add error toast
      return;
    }

    this.store
      .dispatch(
        new InviteUsersToTenantAndSpaces({
          users: Object.values(users).map((user) => ({
            email: user.email,
            spaces: user.spaces,
          })),
        }),
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => this.onInviteCompleted(),
        (err) => {},
      );
  }

  setCandidateMembers(users: UsersDbDto[]) {
    this.usersFromAllTenants = this.prepareTenantMembers(users);

    this.candidateEntityMembers = this.usersFromAllTenants.filter(
      (member) => member.email !== this.currentUser.email && !member.isAssistant,
    );
  }

  asList(items: string[]): string {
    const formatter = new Intl.ListFormat('en', {
      style: 'long',
      type: 'conjunction',
    });

    return formatter.format(items);
  }

  selectEvent(item, isNewUser = false) {
    if (item.type === 'keydown' && this.mobileOs !== 'ios') {
      return;
    }

    if (
      this.invitedMembers.some(
        (invitedMember) => invitedMember.controls.email.value === this.searchedInput,
      )
    ) {
      this.toastr.info(
        this.translocoService.translate('toastr.user-already-invited', {
          email: this.searchedInput,
        }),
      );
      return;
    }

    if (this.searchedInput) {
      const listOfUniqueEmails = [
        ...new Set(this.searchedInput.split(';').map((email) => email.trim())),
      ];

      if (listOfUniqueEmails?.length > 1) {
        this.onEnterEmailList(item, listOfUniqueEmails);
        return;
      }
    }

    if (!isNewUser) {
      this.isSelectedExist = this.candidateEntityMembers.some((user) => user.email === item.email);
    } else if (this.isSelectedExist) {
      this.isSelectedExist = false;
      return;
    }
    if (
      Object.values(this.form.value).some((member) => {
        let userAlreadyInList;
        if (member.id && this.searchedInput) {
          userAlreadyInList = this.usersFromAllTenants.some(
            (tenantMember) =>
              tenantMember._id === member.id && tenantMember.email === this.searchedInput,
          );
        }

        return (
          member.userName === item.userName ||
          member.email === item.userName ||
          member.email === this.searchedInput ||
          userAlreadyInList
        );
      })
    ) {
      this.toastr.info(
        this.translocoService.translate('toastr.user-already-in-the-list', {
          email: this.searchedInput,
        }),
      );
      return;
    }
    if (!isNewUser) {
      this.selectedMember = item;
      this.addMemberToInvitedList();
    } else if (this.selectedMember) {
      this.addMemberToInvitedList();
    } else {
      this.toastr.info(
        this.translocoService.translate('toastr.select-member-from-the-suggestion-list'),
      );
    }
  }

  onChangeSearch(input: string) {
    this.searchedInput = input;

    if (this.isValidEmail(input)) {
      this.selectedMember = {
        email: input,
        isExternalUser: true,
      };
    }
  }

  isValidEmail(input: string): boolean {
    return REGEXP_EMAIL.test(input);
  }

  onCleared(e) {
    this.searchedInput = '';
    this.selectedMember = '';
    this.autocomplete.close();
  }

  inviteAllCandidates(checkbox): void {
    if (!checkbox.target.checked) {
      this.availableSpacesOfMembers = {};

      for (const member of Object.values(this.form.controls)) {
        this.form.removeControl(member.controls.email.value);
      }

      this.candidateEntityMembers = this.usersFromAllTenants.filter(
        (member, index) => member._id !== this.currentUser._id && !member.isAssistant,
      );

      return;
    }

    for (const member of this.candidateEntityMembers) {
      this.availableSpacesOfMembers[member.email] = {
        spaces: this.spaces,
        count: this.spaces.length,
      };

      const newGroup = new FormGroup({
        id: new FormControl(member._id),
        email: new FormControl(member.email),
        userName: new FormControl(member.userName),
        spaces: new FormArray([]),
        isExternal: new FormControl<boolean>(false),
      });

      this.form.addControl(member.email, newGroup);

      newGroup.controls.spaces.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((spaces) => {
        this.availableSpacesOfMembers[newGroup.controls.email.value].spaces = this.spaces.filter(
          (space) => !spaces.some((s) => s.spaceId === space.id),
        );
      });
    }

    this.candidateEntityMembers = [];
  }

  addMemberToInvitedList() {
    MixpanelService.trackEvent('Tenant Invite: Add member to invited list');

    this.availableSpacesOfMembers[this.selectedMember.email] = {
      spaces: this.spaces,
      count: this.spaces.length,
    };

    const newGroup = new FormGroup({
      id: new FormControl<string>(this.selectedMember._id),
      email: new FormControl<string>(this.selectedMember.email),
      userName: new FormControl<string>(this.selectedMember.userName),
      spaces: new FormArray([]),
      isExternal: new FormControl<boolean>(!this.selectedMember._id),
    });

    this.form.addControl(this.selectedMember.email, newGroup);

    newGroup.controls.spaces.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((spaces) => {
      this.availableSpacesOfMembers[newGroup.controls.email.value].spaces = this.spaces.filter(
        (space) => !spaces.some((s) => s.spaceId === space.id),
      );
    });

    if (this.selectedMember._id) {
      this.candidateEntityMembers = this.candidateEntityMembers.filter(
        (item) => item._id !== this.selectedMember._id,
      );
    }

    this.selectedMember = undefined;
    this.autocomplete.clear();
  }

  removeInvitedMember(memberToRemove: { email: string; isExternal: boolean }) {
    this.autocomplete.clear();

    delete this.availableSpacesOfMembers[memberToRemove.email];

    this.form.removeControl(memberToRemove.email);

    if (!memberToRemove.isExternal) {
      const includedMembers = this.invitedMembers.map((member) => member.controls.email.value);

      this.candidateEntityMembers = this.usersFromAllTenants.filter(
        (member, index) =>
          member._id !== this.currentUser._id &&
          !member.isAssistant &&
          !includedMembers.includes(member.email),
      );
    }
  }

  doesMemberContainEmptyRole(): boolean {
    const invitedMembersWithNoRole = Object.values(this.form.controls).filter((member) =>
      member.controls.spaces.controls.some((space) => !space.controls.roleName.value),
    );

    return invitedMembersWithNoRole.length !== 0;
  }

  close(): void {
    MixpanelService.trackEvent('Tenant Invite: Close');
    this.activeModal.close();
  }

  onPaste(event: ClipboardEvent) {
    MixpanelService.trackEvent('Tenant Invite: Paste');
    const pastedText = event.clipboardData.getData('text');

    const filterText = this.candidateEntityMembers.filter((user) =>
      user.email.includes(pastedText),
    );

    this.autocomplete.toHighlight = pastedText;
    this.autocomplete.filteredList = filterText;
    this.autocomplete.notFound = !filterText.length;

    this.onChangeSearch(pastedText);
  }

  onEnterEmailList(event: KeyboardEvent, listOfUniqueEmails: string[]) {
    MixpanelService.trackEvent('Members Invite: Enter email list');
    if (listOfUniqueEmails.length > this.enteredEmailListLengthLimit) {
      this.toastr.info(
        this.translocoService.translate('toastr.email-list-length-limit', {
          limit: this.enteredEmailListLengthLimit,
        }),
      );
      return;
    }

    this.searchedInput = '';
    listOfUniqueEmails.forEach((email) => {
      if (!this.isValidEmail(email)) {
        return;
      }
      this.searchedInput = email;
      const userFromCandidate = this.candidateEntityMembers.find((user) => user.email === email);
      this.isSelectedExist = !!userFromCandidate;
      if (this.isSelectedExist) {
        this.selectEvent(userFromCandidate, false);
      } else {
        this.onChangeSearch(email);
      }
      this.selectEvent(event, true);
    });
  }

  initMainLogic() {
    this.store.dispatch(new RolesGet());

    this.roles = this.store.select(RolesState.getRoles).pipe(
      takeUntil(this.destroy$),
      map((items) =>
        items
          .filter((item) => item.object === 'spaces')
          .map((role) => ({
            name: role.roleName,
          })),
      ),
    );

    this.store
      .select(UsersState.usersofAllTenantsOfCurrentUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => this.setCandidateMembers(result));

    this.spaces = this.store
      .selectSnapshot(SpacesState.getLoadedSpaces)
      .filter((space) => !space.isPersonal)
      .map((space) => ({
        id: space._id,
        name: space.spaceName,
      }));
  }

  addSpaceToInvitedMember(
    email: string,
    spaces: {
      id: string;
      name: string;
    }[],
  ): void {
    if (!spaces.length) {
      return;
    }

    const invitedMember = this.form.controls[email];
    const spacesOfInvitedMember = invitedMember.controls.spaces;

    const group = new FormGroup({
      trackId: new FormControl(v4()),
      spaceId: new FormControl(undefined, {
        validators: [Validators.required],
      }),
      roleName: new FormControl('Space Member', {
        validators: [Validators.required],
      }),
    });

    spacesOfInvitedMember.push(group);
    if (spaces.length === 1) {
      setTimeout(() => {
        group.patchValue({
          spaceId: spaces[0].id,
        });
      }, 0);
    }
  }

  removeSpaceToInvitedMember(email: string, index: number): void {
    const removedSpace = this.form.controls[email].controls.spaces.controls[index];

    const spaceName = this.spaces.find(
      (space) => space.id === removedSpace.controls.spaceId.value,
    )?.name;

    if (spaceName) {
      this.availableSpacesOfMembers[email].spaces.push({
        id: removedSpace.controls.spaceId.value,
        name: spaceName,
      });
    }

    const invitedMember = this.form.controls[email];
    const spaces = invitedMember.controls.spaces;

    spaces.removeAt(index);
  }
}
