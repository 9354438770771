<ng-container *transloco="let t; read: 'tenant-invite'">
  @if (inviteCompleted) {
    <div>
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="invitation-sent" t-id="tenant-invite-alert">
            <img
              src="assets/img/svg/invitation-sent-{{
                config?.layout.variant === 'Light' ? 'light' : 'dark'
              }}.svg"
              class="image"
              alt="invitation-sent"
            />
            <div class="message">{{ t('invite-success') }}</div>
            <button class="btn btn-large btn-subtle" (click)="close()">
              {{ t('invite-close') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  } @else {
    <div class="invitation-list-wrapper">
      <ng-autocomplete
        t-id="tenant-invite-add-email-input"
        #autocomplete
        [placeholder]="t('invite-placeholder')"
        [notFoundText]="t('invite-not-found')"
        [focusFirst]="true"
        [data]="candidateEntityMembers"
        [searchKeyword]="keyword"
        [itemTemplate]="itemTemplate"
        [notFoundTemplate]="notFoundTemplate"
        (inputChanged)="onChangeSearch($event)"
        (inputCleared)="onCleared($event)"
        (keyup.enter)="selectEvent($event, true)"
        (keydown.enter)="selectEvent($event, true)"
        (paste)="onPaste($event)"
        (selected)="selectEvent($event)"
      ></ng-autocomplete>

      <div class="members-invite-all d-flex align-middle">
        <input type="checkbox" class="mt-1 mr-1" (change)="inviteAllCandidates($event)" />
        {{
          t('invite-checkbox', {
            value: currentUser.tenantName,
            object: t('invite-workplace')
          })
        }}
      </div>

      <ng-template #itemTemplate let-item>
        <div class="d-flex align-items-center p-1">
          <app-avatar
            [userId]="item._id"
            [width]="40"
            [height]="40"
            [showStatus]="false"
            [notShowProfile]="true"
          ></app-avatar>
          <div class="invited-members-container">
            <div class="flex flex-row gap-2 items-center">
              <div class="invited-members-username no-mb" [innerHTML]="item.userName"></div>
              @if (item.aka.length) {
                <div class="text-gray-400">
                  {{ 'AKA: ' + asList(item.aka) | limitTo: (platform !== 'web' ? 15 : 30) }}
                </div>
              }
            </div>
            <div class="invited-members-email">{{ item.email }}</div>
          </div>
        </div>
      </ng-template>

      <ng-template #notFoundTemplate let-notFound>
        <div [innerHTML]="notFound"></div>
      </ng-template>

      @if (invitedMembers.length === 0) {
        <div class="invitation-empty-state invitation-list">
          <img
            src="assets/img/svg/invite-empty-state-{{
              config?.layout.variant === 'Light' ? 'light' : 'dark'
            }}.svg"
            class="image"
            alt="empty-state"
          />
        </div>
      }

      @if (invitedMembers.length) {
        <div [formGroup]="form" class="invited-members-list invitation-list">
          <ng-scrollbar
            [autoHeightDisabled]="false"
            visibility="hover"
            thumbClass="white-scrollbars"
          >
            <cdk-accordion class="flex flex-col divide-y">
              @for (
                member of invitedMembers;
                track member.controls.email.value;
                let index = $index
              ) {
                <cdk-accordion-item
                  [formGroupName]="member.controls.email.value"
                  #accordionItem="cdkAccordionItem"
                  class="py-2"
                  role="button"
                  tabindex="0"
                  [attr.id]="'accordion-header-' + index"
                  [attr.aria-expanded]="accordionItem.expanded"
                  [attr.aria-controls]="'accordion-body-' + index"
                  [ngClass]="{
                    'bg-gray-200 dark:bg-gray-900': accordionItem.expanded
                  }"
                >
                  <header
                    class="w-full flex flex-row items-center gap-2"
                    (click)="accordionItem.toggle()"
                  >
                    <i
                      class="ft-chevron-up transition-all duration-300 text-xl"
                      [ngClass]="{
                        'rotate-180': accordionItem.expanded,
                        'rotate-90': !accordionItem.expanded
                      }"
                    ></i>
                    <div class="flex flex-row items-center justify-between w-full">
                      <div class="flex items-center">
                        <i
                          *ngIf="member.controls.isExternal.value"
                          class="fa fa-envelope-o envelope-icon"
                          aria-hidden="true"
                        ></i>
                        <app-avatar
                          *ngIf="!member.controls.isExternal.value"
                          [userId]="member.controls.id.value"
                          [width]="44"
                          [height]="44"
                          [showStatus]="false"
                          [notShowProfile]="true"
                        ></app-avatar>
                        <div>
                          <div class="invited-members-username">
                            {{
                              member?.controls?.userName?.value
                                | limitTo: (platform !== 'web' ? 15 : 30)
                            }}
                          </div>
                          <div class="invited-members-email">
                            {{
                              member?.controls?.email?.value
                                | limitTo: (platform !== 'web' ? 15 : 30)
                            }}
                          </div>
                        </div>
                      </div>
                      <app-svg
                        (click)="
                          removeInvitedMember({
                            email: member.controls.email.value,
                            isExternal: member.controls.isExternal.value
                          })
                        "
                        name="times"
                        width="10"
                        height="10"
                        class="svg-icon w-fit"
                        aria-hidden="true"
                      ></app-svg>
                    </div>
                  </header>
                  <ng-container *ngIf="roles | async as roles">
                    <div
                      formArrayName="spaces"
                      class="flex flex-col gap-2 p-2"
                      role="region"
                      [style.display]="accordionItem.expanded ? '' : 'none'"
                      [attr.id]="'accordion-body-' + index"
                      [attr.aria-labelledby]="'accordion-header-' + index"
                    >
                      @for (
                        space of member.controls.spaces.controls;
                        track space.controls.trackId.value;
                        let j = $index
                      ) {
                        <div [formGroupName]="j" class="flex flex-row gap-2 md:gap-5">
                          <ng-select
                            t-id="tenant-invite-select-space"
                            class="select-role w-full"
                            bindLabel="name"
                            bindValue="id"
                            appendTo="body"
                            [placeholder]="t('select-space')"
                            formControlName="spaceId"
                            [items]="availableSpacesOfMembers[member.controls.email.value].spaces"
                            [clearable]="false"
                            [searchable]="false"
                            [closeOnSelect]="true"
                          ></ng-select>

                          <ng-select
                            t-id="tenant-invite-select-role"
                            class="select-role w-full"
                            bindLabel="name"
                            bindValue="name"
                            appendTo="body"
                            [placeholder]="t('invite-role')"
                            formControlName="roleName"
                            [items]="roles"
                            [clearable]="false"
                            [searchable]="false"
                            [closeOnSelect]="true"
                          ></ng-select>

                          <button
                            (click)="removeSpaceToInvitedMember(member.controls.email.value, j)"
                          >
                            <app-svg
                              name="times"
                              width="8"
                              height="8"
                              class="svg-icon w-fit"
                              aria-hidden="true"
                            ></app-svg>
                          </button>
                        </div>
                      }

                      @if (
                        availableSpacesOfMembers[member.controls.email.value].count >
                          member.controls.spaces.controls.length &&
                        availableSpacesOfMembers[member.controls.email.value].spaces.length
                      ) {
                        <button
                          class="px-2 py-1 w-fit mx-auto btn btn-solid"
                          (click)="
                            addSpaceToInvitedMember(
                              member.controls.email.value,
                              availableSpacesOfMembers[member.controls.email.value].spaces
                            )
                          "
                        >
                          {{ t('add-space') }}
                        </button>
                      } @else {
                        <span class="text-gray-400 text-center">{{ t('no-space') }}</span>
                      }
                    </div>
                  </ng-container>
                </cdk-accordion-item>
              }
            </cdk-accordion>
          </ng-scrollbar>
        </div>
      }

      @if (invitedMembers.length) {
        <div class="invite-modal-footer">
          <ng-content select="[previous-step-button]"></ng-content>
          <button
            t-id="tenant-invite-invite-action"
            type="button"
            class="btn btn-solid"
            (click)="usersInviteClick()"
          >
            {{ t('invite-btn') }}
          </button>
        </div>
      }
    </div>
  }
</ng-container>
